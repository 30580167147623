<template>
  <div class="project-text">
    <div class="row justify-content-center">
      <div class="col col-md-12 col-lg-9">
        <h1 v-if="title" class="Nexa-Book title">{{title}}</h1>
        <p v-if="lead" class="NexaText-Regular lead">{{lead}}</p>
        <p v-if="content" class="NexaText-Light content" v-html="content"></p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ProjectText',
      props: {
      title: String,
      lead: String,
      content: String
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  .project-text{
    padding-top: 30px;
    padding-bottom: 30px;
    .title{
      padding-bottom: 10px;
      color: #666666;
    }
    .lead{
      padding-bottom: 5px;
      line-height: 30px;
    }
    .content{
      line-height: 30px;
    }
  }
</style>
